<template>
  <transition>
    <div>
      <div class="flex space-x-2 inner absolute z-50">
        <div class="mt-2">근무일</div>
        <DxDateBox
          :styling-mode="config.stylingMode"
          width="120"
          height="30"
          v-model="config.workDt"
          type="date"
          dateSerializationFormat="yyyyMMdd"
          display-format="yyyy.MM.dd"
          :min="config.today"
        >
          <DxValidator>
            <DxRequiredRule message="근무일 필수입니다." />
          </DxValidator>
        </DxDateBox>
        <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
      </div>
      <esp-dx-data-grid :data-grid="replacementInfoGrid" ref="replacementInfoGrid" />
    </div>
  </transition>
</template>

<script>
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import { DxButton } from 'devextreme-vue/button';
  import { getPastFromToday, isSuccess } from '@/plugins/common-lib';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm;

  export default {
    components: {
      EspDxDataGrid,
      DxButton,
      DxDateBox,
      DxRequiredRule,
      DxValidator,
    },
    props: {
      contentData: Object,
    },
    watch: {},
    data() {
      return {
        config: {
          stylingMode: 'outlined', //outlined, underlined, filled
          workDt: getPastFromToday(-1, 'days'),
          today: getPastFromToday(-1, 'days'),
        },
        replacementInfoGrid: {
          keyExpr: 'id',
          refName: 'replacementInfoGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          // height:'500',    // 주석처리시 100%
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {},
          showActionButtons: {
            customButtons: [],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
            operationDescriptions: {
              contains: '포함',
            },
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '부서정보',
              dataField: 'deptNmPath',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
            },
            {
              caption: '상담사[ID]',
              dataField: 'agtNm',
              alignment: 'center',
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              calculateSortValue: data => `${data.agtNm} [${data.agtId}]`,
              calculateCellValue: rowData => {
                return `${rowData.agtNm} [${rowData.agtId}]`;
              },
            },
            {
              caption: '일반근무유형',
              dataField: 'workBasicNm',
              alignment: 'center',
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
            },
            {
              caption: '일반근무시간',
              dataField: 'workBasicStartTime',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              calculateDisplayCellValue: rowData => {
                if (rowData.workBasicStartTime) {
                  return `${rowData.workBasicStartTime} ~ ${rowData.workBasicEndTime}`;
                }
              },
            },
            {
              caption: '시간외근무유형',
              dataField: 'workOverTimeNm',
              alignment: 'center',
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
            },
            {
              caption: '시간외근무시간',
              dataField: 'workOverTimeStartTime',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              calculateDisplayCellValue: rowData => {
                if (rowData.workOverTimeStartTime) {
                  return `${rowData.workOverTimeStartTime} ~ ${rowData.workOverTimeEndTime}`;
                }
              },
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      async selectDataList() {
        if (!vm.config.workDt) {
          return;
        }

        const payload = {
          actionname: 'EWM_PERSONAL_WORK_SCHEDULE_LOG',
          data: {
            startDt: this.config.workDt,
          },
          loading: false,
        };

        const res = await vm.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.replacementInfoGrid.dataSource = res.data.data;
        }
      },
      /** @description : 라이프사이클 created 호출되는 메서드 */
      createdData() {
        vm = this;

        this.selectDataList();
      },
      /** @description : 라이프사이클 mounted 호출되는 메서드 */
      mountedData() {
        this.$watch(
          () => {
            return this.$refs.replacementInfoGrid.selectedRowsData;
          },
          val => {
            val = this.$_commonlib.cloneObj(val);
            this.$emit('input', { val });
          },
        );
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountedData();
    },
  };
</script>
